<template>
    <div>
        <ul>
            <li>Pentru OpenCart 3.x este necesar sa folositi extensia <el-link type="primary" @click="afisareModalConfirmare(3)">CelMarketplace-OC3</el-link>. Link <el-link type="primary" href="https://www.youtube.com/embed/Gv8TkTss0Kg" target="_blank">Tutorial Video OpenCart 3.x</el-link></li>
            <br>
            <li>Pentru OpenCart 2.x este necesar sa folositi extensia <el-link type="primary" @click="afisareModalConfirmare(2)">CelMarketplace-OC2</el-link>. Link <el-link type="primary" href="https://www.youtube.com/embed/Y5-dkuRSh0w" target="_blank">Tutorial Video OpenCart 2.x</el-link>
            <br>
            De asemenea, modulul pentru OpenCart 2.x are urmatoarele dependente:<br>
                <ul>
                    <li><el-link href="https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=18892" type="primary" target="_blank">Quick Fix: FTP support disabled (Necesara la instalare)</el-link></li>
                    <li><el-link href="https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=24529" type="primary" target="_blank">ExtensionUninstaller (Necesara la actualizare)</el-link></li>
                </ul>
            </li>
        </ul>
        <br>
        <el-button type="warning" @click="showRequiredResources">{{ $t('shop.api_required_resources') }}</el-button>
        <div v-if="showRequiredResourcesList">
            <ul>
                <li v-for="(rules, index) in requiredResources" :key="index">{{ index }}
                    <span v-for="(rule, indexRule) in rules"
                          :key="indexRule"
                          :class="{'text-green': rule === 'GET', 'text-blue': rule === 'POST', 'text-orange': rule === 'PUT'}">{{ rule }} </span>
                </li>
            </ul>
        </div>
        <div v-if="rules.show">
            <p>{{ rules.message }}</p>
            <ul>
                <li v-for="(rules, index) in rules.requiredResources" :key="index">{{ index }}
                    <span v-for="(rule, indexRule) in rules"
                          :key="indexRule"
                          :class="{'text-green': rule === 'GET', 'text-blue': rule === 'POST', 'text-orange': rule === 'PUT'}">{{ rule }} </span>
                </li>
            </ul>
            <template v-if="Object.keys(rules.additionalResources).length > 0">
                <p>{{ $t('shop.api_additional_resources') }}</p>
                <ul>
                    <li v-for="(rules, index) in rules.additionalResources" :key="index">{{ rules }}</li>
                </ul>
            </template>
        </div>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.name')" v-model="shop.name"></el-input>

        <div class="mini-separator"></div>
        <span>Format: <strong>https://website.ro/</strong></span>
        <el-input :placeholder="$t('shop.site_url')" v-model="shop.shopUrl"></el-input>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.add_api_key')" v-model="shop.api_key_oc"></el-input>

        <div class="mini-separator"></div>
        <el-input :placeholder="$t('shop.add_api_key_id')" v-model="shop.api_key_id"></el-input>

        <template v-if="shop.connect">
            <div class="mini-separator"></div>
            <span>Pentru a activa sincronizarea do comenzi completati datele din tab-ul
                                    <a href="#" @click.prevent="openSettingsTab" style="color: blue">Settings</a>
                                </span>
        </template>

        <div class="mini-separator"></div>
        <template v-if="shop.name.length > 3 && shop.api_key_oc.length > 5 && shop.api_key_id.length > 1 && shop.shopUrl.length > 10">
            <el-button @click="connect" type="primary">{{ $t('shop.check_conn') }}</el-button>
        </template>


        <el-dialog title="OpenCart Extension" :visible.sync="showWarningModal">
            <template v-if="versiuneSelectataOC === 3">
                <ul>
                    <li>Pentru OpenCart 3.x este necesar sa folositi extensia CelMarketplace-OC3. Link <el-link type="primary" href="https://www.youtube.com/embed/Gv8TkTss0Kg" target="_blank">Tutorial Video OpenCart 3.x</el-link></li>
                </ul>
            </template>
            <template v-if="versiuneSelectataOC === 2">
                <ul>
                    <li>Pentru OpenCart 2.x este necesar sa folositi extensia CelMarketplace-OC2. Link <el-link type="primary" href="https://www.youtube.com/embed/Y5-dkuRSh0w" target="_blank">Tutorial Video OpenCart 2.x</el-link>
                    <br>
                    De asemenea, modulul pentru OpenCart 2.x are urmatoarele dependente:<br>
                        <ul>
                            <li><el-link href="https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=18892" type="primary" target="_blank">Quick Fix: FTP support disabled (Necesara la instalare)</el-link></li>
                            <li><el-link href="https://www.opencart.com/index.php?route=marketplace/extension/info&extension_id=24529" type="primary" target="_blank">ExtensionUninstaller (Necesara la actualizare)</el-link></li>
                        </ul>
                    </li>
                </ul>
            </template>

            <center><el-link class="el-button el-button--default" target="_blank" :href="versiuneSelectataOC == 3 ? 'https://api-mp.cel.ro/market_cdn/module/celmarketplace-oc-3-latest.ocmod.zip' : 'https://api-mp.cel.ro/market_cdn/module/celmarketplace-oc-2-latest.ocmod.zip'">Am luat la cunostiinta cele de mai sus, descarca extensia</el-link></center>
        </el-dialog>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                showWarningModal: false,
                versiuneSelectataOC: 0,
                rules: {
                    show: false,
                    message: '',
                    data: [],
                    additionalResources: {}
                },
                requiredResources: {},
                showRequiredResourcesList: false,
            };
        },
        props: {
            shop: {
                type: Object,
            },
            platform: {
                type: String,
            }
        },
        methods: {
            afisareModalConfirmare(versiune) {
                this.versiuneSelectataOC = versiune;
                this.showWarningModal = true;
            },
            showRequiredResources() {
                this.showRequiredResourcesList = !this.showRequiredResourcesList;
                const countResources = Object.keys(this.requiredResources).length;
                if (countResources === 0) {
                    this.$store.dispatch('integrations/getRequiredResources', {
                        platform: this.platform || this.shop.platform,
                    })
                        .then((res) => {
                            if (res.error === 0) {
                                this.requiredResources = res.message.requiredResources;
                            }
                        }).catch(() => {
                    });
                }
            },
            connect() {
                localStorage.setItem('shopUrl', this.shop.shopUrl);
                this.rules = {
                    show: false,
                    message: '',
                    data: []
                };
                this.$store.dispatch('integrations/checkConnectionToShop', {
                    ...this.shop,
                    platform: this.platform
                })
                    .then((res) => {
                        if (res.error === true) {
                            this.$notify.error(this.$t('shop.conn_err'));
                            if (typeof res.message.requiredResources !== 'undefined') {
                                this.rules = {
                                    show: true,
                                    message: res.message.message,
                                    requiredResources: res.message.requiredResources,
                                    additionalResources: res.message.additionalResources,
                                };
                            }
                        }
                        if (res.error === 0) {
                            this.$router.push({
                                name: 'shopView',
                                params: {
                                    id: res.message.id
                                }
                            }).catch(() => {});
                        }
                    }).catch(() => {
                });
            },
            openSettingsTab() {
                this.activeTab = 'settings';
            }
        }
    };
</script>
